type Delivery = {
    id: string;
    name: string;
    investigator?: string;
    shortDescription?: string;
    longDescription?: string;
    parent?: string;
    manifestFileDownloadLink?: string;
    bossLink?: string;
    date: number;
    final?: boolean;
    fileCount?: number;
    sizeGB?: number;
};

const API_BASE = "https://biccn.bossdb.org";

function getDeliveries(): Promise<Delivery[]> {
    return fetch(`${API_BASE}/api/deliveries`)
        .then(
            // @ts-ignore
            (res) => res.json()
        )
        .then((res) => res.deliveries);
}

function rewriteS3Link(link: string): string {
    return link.replace(
        "s3://bossdb-open-data/biccn/",
        "https://s3.amazonaws.com/bossdb-open-data/biccn/"
    );
}

function getHistory(delivery: Delivery, deliveryList: Delivery[]): Delivery[] {
    // Recursively get the history as a list of deliveries
    function _getDelivery(id: string): Delivery | undefined {
        return deliveryList.find((d) => d.id === id);
    }

    function _getDeliveryParent(id: string): Delivery | undefined {
        // Find a delivery that has the given id as a parent
        return deliveryList.find((d) => d.parent === id);
    }

    const history: Delivery[] = [delivery];
    let current = delivery;
    while (current.parent) {
        let parent = _getDelivery(current.parent);
        if (!parent) {
            break;
        }
        history.push(parent);
        current = parent;
    }

    // Now walk the linked parent list backwards, getting the predecessors
    let predecessors: Delivery[] = [];
    current = history[0];
    // Find my parent:
    let parent = _getDeliveryParent(current.id);
    while (parent) {
        predecessors.push(parent);
        current = parent;
        parent = _getDeliveryParent(current.id);
    }

    return [...predecessors, ...history].sort((a, b) => b.date - a.date);
}

export { getDeliveries, rewriteS3Link, getHistory, type Delivery };
