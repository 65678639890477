import React from "react";

import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Delivery } from "../api";

import { DeliveryCard } from "./DeliveryCard";

function DeliveriesIndex({ deliveries }: { deliveries: Delivery[] }) {
    let nav = useNavigate();

    return (
        <Row>
            {deliveries
                .sort((a, b) => b.date - a.date)
                .map((proj) => (
                    <Col
                        md={12}
                        key={proj.id}
                        style={{
                            padding: "0.5em",
                        }}
                    >
                        <DeliveryCard
                            small
                            delivery={proj}
                            onSelectCallback={(c) => {
                                nav(`/deliveries/${c.id}`);
                            }}
                        />
                    </Col>
                ))}
        </Row>
    );
}

export default DeliveriesIndex;
