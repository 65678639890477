import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function _Navbar() {
    return (
        <>
            <Container
                fluid
                style={{
                    backgroundColor: "#fff",
                    // backgroundColor: "#2699FB",
                    borderBottom: "1px solid #ccc",
                    height: "4rem",
                    // boxShadow: "0px 0px 10px #999",
                    zIndex: 100,
                }}
            >
                <Row>
                    <Col style={{ margin: "1em" }}>
                        <Link
                            to="/"
                            style={{
                                textDecoration: "none",
                                color: "black",
                                fontFamily: "Georgia, Roboto, sans-serif",
                                letterSpacing: "0.05rem",
                                fontSize: "1.2rem",
                            }}
                        >
                            BICCN Explorer
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
        // <Navbar
        //     bg="dark"
        //     expand="lg"
        //     variant="dark"
        //     // set background color:
        //     style={{
        //         backgroundColor: "#2699FB",
        //         borderBottom: "1px solid #2699FB",
        //     }}
        // >
        //     <Container fluid>
        //         {/* <Navbar.Brand as={Link} to="/">
        //             BICCN Explorer
        //         </Navbar.Brand> */}
        //         <Navbar.Toggle aria-controls="basic-navbar-nav" />
        //         <Navbar.Collapse id="basic-navbar-nav">
        //             <Nav>
        //                 <Nav.Link as={Link} to="/">
        //                     Home
        //                 </Nav.Link>
        //             </Nav>
        //         </Navbar.Collapse>
        //     </Container>
        // </Navbar>
    );
}

export default _Navbar;
