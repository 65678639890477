import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

function Home() {
    return (
        <Container className="Home">
            <Row>
                <Col
                    sm={{
                        span: 10,
                        offset: 1,
                    }}
                >
                    <Card
                        style={{
                            marginTop: "2.5em",
                        }}
                    >
                        <Card.Body>
                            <Card.Title>
                                <h1
                                    style={{
                                        fontSize: "2.5em",
                                        fontWeight: "bold",
                                        fontFamily: "Georgia, serif",
                                        color: "#333",
                                        textAlign: "center",
                                        marginBottom: "1em",
                                    }}
                                >
                                    BossDB BICCN Explorer
                                </h1>
                            </Card.Title>
                            <Card.Text
                                style={{ margin: "auto", maxWidth: "55em" }}
                            >
                                <p>
                                    This application tracks the upload and
                                    revision of{" "}
                                    <a href="https://biccn.org">BICCN</a>{" "}
                                    electron microscopy (EM) and X-ray
                                    volumetric datasets, accompanying metadata,
                                    and associated files.
                                </p>
                                <p>
                                    Volumetric data are stored in{" "}
                                    <a href="https://bossdb.org">BossDB</a>, the
                                    EM and X-ray datastore of record for the{" "}
                                    <a href="https://braininitiative.nih.gov/funding-opportunies/notice-data-sharing-policy-brain-initiative%C2%AE">
                                        NIH BRAIN Initiative
                                    </a>
                                    . Brain Observatory Storage Service and
                                    Database (BossDB) is a volumetric database
                                    for 3D and 4D neuroimaging data. BossDB
                                    focuses primarily on storing volumetric
                                    Electron Microscopy (EM) and X-Ray
                                    Microtomography (XRM) datasets generated as
                                    a part of the BRAIN Initiative. BossDB
                                    stores high resolution, multi-channel image
                                    data with registered segmentations,
                                    annotations, and meshes, and connects to a
                                    number of community resources for data
                                    access and data visualization. BossDB also
                                    stores connectomics datasets and contains a
                                    number of software tools and interfaces for
                                    querying and searching connectomes.
                                </p>
                                <p>
                                    To get started, click on a dataset delivery
                                    from the left side dataset-search pane.
                                </p>
                                <hr style={{ margin: "3em 0" }} />
                                <p>
                                    If you are a dataset owner or contributor
                                    and have questions about BossDB storage, or
                                    if you are interested in using BossDB to
                                    store your own data, please contact{" "}
                                    <a href="mailto:biccn@bossdb.org">
                                        biccn@bossdb.org
                                    </a>
                                    .
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;
