import React from "react";
import { Link } from "react-router-dom";
import { Link as LinkIcon } from "react-bootstrap-icons";

export function DeliveryID({
    delivery,
    short,
    small,
}: {
    delivery: { id: string };
    short?: boolean;
    small?: boolean;
}) {
    return short ? (
        <Link
            to={`/deliveries/${delivery.id}`}
            style={{
                color: "#111",
                fontWeight: "bold",
                fontSize: small ? "0.6rem" : "0.8rem",
                fontFamily: "monospace",
                textDecoration: "none",
                background: small ? "none" : "#BCE0FD",
                borderRadius: "0.5rem",
                padding: small ? "none" : "0.2rem 0.7rem",
            }}
        >
            {delivery.id.split("-")[0]}
        </Link>
    ) : (
        <Link
            to={`/deliveries/${delivery.id}`}
            style={{
                color: "#111",
                fontWeight: "bold",
                fontSize: small ? "0.6rem" : "0.8rem",
                fontFamily: "monospace",
                textDecoration: "none",
                background: small ? "none" : "#BCE0FD",
                borderRadius: "0.5rem",
                padding: small ? "none" : "0.2rem 0.7rem",
            }}
        >
            {small ? null : <LinkIcon />} {delivery.id}
        </Link>
    );
}
