import React from "react";
import { Button, Card } from "react-bootstrap";
import { DeliveryID } from "./DeliveryID";
import { Delivery, getHistory, rewriteS3Link } from "../api";
import { DeliveryCard } from "./DeliveryCard";

import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Save2Fill, StarFill, ThreeDotsVertical } from "react-bootstrap-icons";

function DeliveryHistoryTimeline({ history }: { history: Delivery[] }) {
    function _getIcon(d: Delivery, i: number) {
        if (d.final) {
            return <StarFill />;
        }
        if (i === 0) {
            return <Save2Fill />;
        }
        return <ThreeDotsVertical />;
    }
    function _getIconColor(d: Delivery, i: number): string {
        if (d.final) {
            return "#157347";
        }
        if (i === 0) {
            return "#0b5ed7";
        }
        return "#5c636a";
    }
    return (
        <VerticalTimeline layout="1-column-left" animate={false}>
            {history.map((delivery, i) => (
                <VerticalTimelineElement
                    key={delivery.id}
                    className="vertical-timeline-element--work"
                    date={new Date(delivery.date).toLocaleDateString()}
                    icon={_getIcon(delivery, i)}
                    iconStyle={{
                        background: _getIconColor(delivery, i),
                        color: "white",
                    }}
                >
                    <DeliveryCard small delivery={delivery} />
                </VerticalTimelineElement>
            ))}
        </VerticalTimeline>
    );
}

export function DeliveryDetails({
    delivery,
    deliveryList,
}: {
    delivery: Delivery;
    deliveryList?: Delivery[];
}) {
    if (!deliveryList) {
        deliveryList = [];
    }
    let history = getHistory(delivery, deliveryList);
    return (
        <Card style={{ background: "#F1F9FF", border: "none" }}>
            <Card.Body>
                <Card.Title>
                    <div
                        style={{
                            marginTop: "0.5rem",
                            fontSize: "0.8rem",
                            color: "#666",
                            cursor: "pointer",
                            textAlign: "center",
                            marginBottom: "2rem",
                        }}
                    >
                        <DeliveryID delivery={delivery} />
                    </div>
                    <h1
                        style={{
                            fontFamily: "Georgia, serif",
                            fontSize: "2rem",
                            textDecoration: "none",
                            color: "#666",
                            textAlign: "center",
                            alignContent: "center",
                        }}
                    >
                        <span>{delivery.name}</span>
                    </h1>
                    <div
                        style={{
                            fontWeight: "300",
                            fontSize: "0.9rem",
                            color: "#2699FB",
                            alignContent: "center",
                            textAlign: "center",
                            marginBottom: "2rem",
                        }}
                    >
                        <span style={{ color: "#2699FB" }}>
                            {delivery.shortDescription}
                        </span>
                    </div>
                </Card.Title>
                <div style={{ color: "#666" }}>
                    <div style={{ marginBottom: "3em" }}>
                        {delivery.longDescription ? (
                            <div>{delivery.longDescription}</div>
                        ) : (
                            <div style={{ color: "#888", fontStyle: "italic" }}>
                                This project has not yet been uploaded.
                            </div>
                        )}
                    </div>
                    <div style={{ marginBottom: "3em" }}>
                        {delivery.manifestFileDownloadLink ? (
                            <Button
                                size="sm"
                                variant="primary"
                                style={{ marginRight: "1rem" }}
                                href={rewriteS3Link(
                                    delivery.manifestFileDownloadLink
                                )}
                            >
                                Download Manifest
                            </Button>
                        ) : (
                            <Button
                                size="sm"
                                variant="primary"
                                style={{ marginRight: "1rem" }}
                                href="#"
                                disabled
                            >
                                Manifest Unavailable
                            </Button>
                        )}{" "}
                        {delivery.bossLink ? (
                            <Button size="sm" variant="primary">
                                View on BossDB.org
                            </Button>
                        ) : null}
                    </div>
                    {delivery.manifestFileDownloadLink ? (
                        <div style={{ marginBottom: "4em" }}>
                            <h2>Download</h2>
                            <div>
                                Data can be downloaded directly from S3 using
                                the <code>awscli</code> tool (which can be
                                installed by running{" "}
                                <code>pip install awscli</code>).
                            </div>
                            <pre
                                style={{
                                    background: "#BCE0FD",
                                    padding: "1rem",
                                    borderRadius: "0.5rem",
                                }}
                            >
                                aws s3 cp s3://bossdb-open-data/biccn/
                                {delivery.id} .
                            </pre>
                        </div>
                    ) : null}
                    <div style={{ marginBottom: "4em" }}>
                        <h2>Statistics</h2>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "10em auto",
                                // alignItems: "space-between",
                                margin: "0.25em",
                            }}
                        >
                            <span>Total Size (GB)</span>
                            <div>
                                {/* @ts-ignore */}
                                {delivery.sizeGB || "Unknown"}
                            </div>
                            <span>File Count</span>
                            <div>
                                {/* @ts-ignore */}
                                {delivery.fileCount || "Unknown"}
                            </div>
                        </div>
                        {delivery.parent ? (
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "10em auto",
                                    // alignItems: "space-between",
                                    margin: "0.25em",
                                }}
                            >
                                <span>Parent</span>
                                <div>
                                    <DeliveryID
                                        delivery={{ id: delivery.parent }}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div style={{ marginBottom: "4em" }}>
                        <h2>Provenance</h2>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "10em auto",
                                // alignItems: "space-between",
                                margin: "0.25em",
                            }}
                        >
                            <span>This delivery</span>
                            <div>
                                <DeliveryID delivery={delivery} />
                            </div>
                        </div>
                        {delivery.parent ? (
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "10em auto",
                                    // alignItems: "space-between",
                                    margin: "0.25em",
                                }}
                            >
                                <span>Parent</span>
                                <div>
                                    <DeliveryID
                                        delivery={{ id: delivery.parent }}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div style={{ marginBottom: "4em" }}>
                        <h2>History</h2>
                        {history.length > 1 ? (
                            <div>
                                <DeliveryHistoryTimeline history={history} />
                            </div>
                        ) : null}
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}
