import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Home";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import DeliveryPage from "./pages/DeliveryPage";
import { Container } from "react-bootstrap";

function LayoutWithNavbar() {
    return (
        <>
            <Navbar />
            <Container fluid>
                <Sidebar page={<Outlet />} />
            </Container>
        </>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LayoutWithNavbar />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/deliveries/:id" element={<DeliveryPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
