import React from "react";
import { useParams } from "react-router-dom";
import { DeliveryDetails } from "../components/DeliveryDetails";
import { getDeliveries } from "../api";
import { Delivery } from "../api";
import { Container, Row, Col } from "react-bootstrap";

function DeliveryPage() {
    let params = useParams();

    // Fetch deliveries from API:
    let [deliveries, setDeliveries] = React.useState<Delivery[]>([]);

    React.useEffect(() => {
        getDeliveries().then((deliveries) => setDeliveries(deliveries));
    }, []);

    let delivery = deliveries.find((d) => d.id === params.id);
    if (!delivery) {
        return <div>Delivery not found</div>;
    }
    return (
        <div className="DeliveryPage">
            <Container>
                <Row>
                    <Col
                        sm={{
                            span: 10,
                            offset: 1,
                        }}
                    >
                        <div style={{ marginTop: "1em" }}>
                            <DeliveryDetails
                                delivery={delivery}
                                deliveryList={deliveries}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DeliveryPage;
