import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Delivery, getDeliveries } from "../api";
import DeliveriesList from "./DeliveriesList";

export default function Sidebar({ page }: { page: JSX.Element }) {
    let [finalDeliveriesOnly, setFinalDeliveriesOnly] = React.useState(false);
    let [sortByAttribute, setSortByAttribute] = React.useState("date");

    // Fetch deliveries from API:
    let [deliveries, setDeliveries] = React.useState<Delivery[]>([]);
    React.useEffect(() => {
        getDeliveries().then((deliveries) => setDeliveries(deliveries));
    }, []);

    let filteredDeliveries = deliveries;
    if (finalDeliveriesOnly) {
        filteredDeliveries = deliveries
            .filter((d) => d.final)
            .sort((a, b) => {
                if (sortByAttribute === "date") {
                    return a.date - b.date;
                } else if (sortByAttribute === "name") {
                    return a.name.localeCompare(b.name);
                } else {
                    return a.id.localeCompare(b.id);
                }
            });
    }
    return (
        <>
            <Container
                fluid
                style={{
                    backgroundColor: "#fff",
                    // backgroundColor: "#2699FB",
                    borderBottom: "1px solid #ccc",
                    height: "4rem",
                    // boxShadow: "0px 0px 10px #999",
                    zIndex: 100,
                }}
            >
                <Row>
                    <Col style={{ margin: "1em" }}>
                        <Button
                            variant={
                                finalDeliveriesOnly
                                    ? "primary"
                                    : "outline-secondary"
                            }
                            style={{
                                borderRadius: "2em",
                                marginRight: "1.5em",
                                padding: "0.25em 1.5em 0.25em 1.5em",
                            }}
                            size="sm"
                            onClick={() => {
                                setFinalDeliveriesOnly(!finalDeliveriesOnly);
                            }}
                        >
                            Final deliveries only
                        </Button>
                        <Button
                            variant={
                                sortByAttribute === "date"
                                    ? "primary"
                                    : "outline-secondary"
                            }
                            style={{
                                borderRadius: "2em",
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 0,
                                padding: "0.25em 1.5em 0.25em 1.5em",
                            }}
                            size="sm"
                            onClick={() => {
                                setSortByAttribute("date");
                            }}
                        >
                            Sort by date
                        </Button>
                        <Button
                            variant={
                                sortByAttribute === "name"
                                    ? "primary"
                                    : "outline-secondary"
                            }
                            style={{
                                borderRadius: "2em",
                                borderBottomLeftRadius: 0,
                                borderTopLeftRadius: 0,
                                marginRight: "1.5em",
                                padding: "0.25em 1.5em 0.25em 1.5em",
                            }}
                            size="sm"
                            onClick={() => {
                                setSortByAttribute("name");
                            }}
                        >
                            Sort by name
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col
                        xl={3}
                        md={4}
                        xs={5}
                        style={{
                            backgroundColor: "#fff",
                            // boxShadow: "0px 0px 10px #999",
                            borderRight: "1px solid #ccc",
                            height: "calc(100vh - 8rem)",
                            paddingTop: "1em",
                        }}
                    >
                        <a
                            href="https://bossdb.org"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-grid gap-2"
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                variant="link"
                                style={{
                                    textTransform: "uppercase",
                                    textDecoration: "none",
                                    color: "#333",
                                    fontFamily: "Roboto, sans-serif",
                                    letterSpacing: "0.1rem",
                                    textAlign: "left",
                                }}
                            >
                                bossdb{" "}
                                <BoxArrowUpRight
                                    height={20}
                                    width={20}
                                    style={{ verticalAlign: "top" }}
                                />
                            </Button>
                        </a>
                        <Link
                            to="/"
                            className="d-grid gap-2"
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                variant="link"
                                style={{
                                    textTransform: "uppercase",
                                    textDecoration: "none",
                                    color: "#333",
                                    fontFamily: "Roboto, sans-serif",
                                    letterSpacing: "0.1rem",
                                    textAlign: "left",
                                }}
                            >
                                Deliveries
                            </Button>
                        </Link>
                        {filteredDeliveries.length ? (
                            <DeliveriesList deliveries={filteredDeliveries} />
                        ) : (
                            "No dataset deliveries match your search."
                        )}
                    </Col>
                    <Col xl={9} md={8} xs={7}>
                        <div
                            style={{
                                maxHeight: "calc(100vh - 8rem)",
                                overflowY: "scroll",
                            }}
                        >
                            {page}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
