import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Save2Fill, StarFill } from "react-bootstrap-icons";
import { Delivery } from "../api";
import { DeliveryID } from "./DeliveryID";

export function DeliveryCard({
    delivery,
    onSelectCallback,
    isSelected,
    small,
}: {
    delivery: Delivery;
    onSelectCallback?: (delivery: Delivery) => void;
    isSelected?: boolean;
    small?: boolean;
}) {
    onSelectCallback = onSelectCallback || (() => {});
    return (
        <Card
            style={{
                // background: "#F1F9FF",
                border: isSelected ? "1px solid #BCE0FD" : "1px solid #eee",
                // border: "1px solid #ccc",
            }}
        >
            <Card.Body>
                <Card.Title>
                    <h1>
                        <div
                            // @ts-ignore
                            onClick={() => onSelectCallback(delivery)}
                            style={{
                                fontFamily: "Georgia, serif",
                                fontSize: small ? "1rem" : "1.5rem",
                                textDecoration: "none",
                                color: "#666",
                                display: "grid",
                                gridTemplateColumns: "2.5rem auto",
                                cursor: "pointer",
                            }}
                        >
                            {delivery.final ? (
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            This project has been finalized.
                                        </Tooltip>
                                    }
                                >
                                    <StarFill
                                        style={{
                                            color: "#2699FB",
                                            marginRight: "0.6rem",
                                            fontSize: small
                                                ? "1.2rem"
                                                : "1.75rem",
                                        }}
                                    />
                                </OverlayTrigger>
                            ) : (
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            This is not a final data delivery.
                                        </Tooltip>
                                    }
                                >
                                    <Save2Fill
                                        style={{
                                            color: "#2699FB",
                                            marginRight: "0.6rem",
                                            fontSize: small
                                                ? "1.2rem"
                                                : "1.75rem",
                                        }}
                                    />
                                </OverlayTrigger>
                            )}
                            <div>{delivery.name}</div>
                        </div>
                    </h1>
                    <div
                        style={{
                            fontWeight: "300",
                            fontSize: small ? "0.7rem" : "0.8rem",
                            color: "#2699FB",
                            display: "grid",
                            gridTemplateColumns: "2.5rem auto",
                        }}
                    >
                        <div></div>
                        <div>{delivery.shortDescription}</div>
                    </div>

                    <div
                        style={{
                            marginTop: "0.5rem",
                            fontSize: "0.6rem",
                            color: "#2699FB",
                            display: "grid",
                            gridTemplateColumns: "2.5rem auto",
                            cursor: "pointer",
                        }}
                    >
                        <div></div>
                        <div
                            // @ts-ignore
                            onClick={() => onSelectCallback(delivery)}
                            style={{
                                color: "#111",
                                fontWeight: "bold",
                                fontFamily: "monospace",
                                textDecoration: "none",
                            }}
                        >
                            <DeliveryID delivery={delivery} small />
                        </div>
                    </div>
                </Card.Title>
            </Card.Body>
        </Card>
    );
}
